import axios from 'axios';

export async function giveRepositoryAccess({ email, repo }: { email: string, repo: string }) {
  try {
    const resultMake = await axios.post(process.env.REACT_APP_GIVE_REPOSITORY_ACCESS_URL_MAKE as string, {
      email,
      repo
    })

    return resultMake.data
  } catch (error) {
    console.error(error)
    
    try {
      const result = await axios.post(process.env.REACT_APP_GIVE_REPOSITORY_ACCESS_URL as string, {
        email,
        repo
      })
      return result.data
    } catch (error) {
      console.error(error)
      throw error;
    }
  }
}