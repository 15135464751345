import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';
import { createValidator } from './validator';

const schema = {
  title: 'Form',
  type: 'object',
  required: ['email', 'repo'],
  properties: {
    email: {
      type: 'string',
      format: 'email',
      label: 'E-mail',
      errorMessage: 'Podaj poprawny adres e-mail',
      minLength: 1,
      uniforms: {
        type: 'email',
        variant: 'outlined',
      },
    },
    repo: {
      type: 'string',
      errorMessage: 'Wybierz repozytorium',
      minLength: 1,
      options: [
        { label: 'HTML oraz CSS: Podstawy', value: 'task-html-and-css-basics' },
        { label: 'HTML oraz CSS: Responsywność', value: 'task-html-and-css-rwd' },
        { label: 'JavaScript: Podstawy', value: 'task-js-basics' },
        { label: 'JavaScript: Elementy DOM', value: 'task-js-dom-elements' },
        { label: 'JavaScript: Zdarzenia', value: 'task-js-events' },
        { label: 'JavaScript: Formularze', value: 'task-js-forms' },
        { label: 'JavaScript: ES2015', value: 'task-js-es2015plus' },
        { label: 'JavaScript: API oraz Fetch', value: 'task-js-api-and-fetch' },
        { label: 'JavaScript: Testowanie', value: 'task-js-testing' },
        { label: 'React: Wprowadzenie', value: 'task-react-introduction' },
        { label: 'React: Komponenty', value: 'task-react-components' },
        { label: 'React: Kompozycja', value: 'task-react-composition' },
        { label: 'React: Nowoczesny', value: 'task-react-modern' },
        { label: 'React: Stylowanie', value: 'task-react-styling' },
        { label: 'React: Redux Postawy', value: 'task-react-redux-basics' },
        { label: 'React: Redux Rozszerzenie', value: 'task-react-redux-enhancement' },
        { label: 'React: Ścieżkowanie', value: 'task-react-routing' },
        { label: 'React: Testowanie', value: 'task-react-testing' },
        { label: 'React: Wytwarzanie', value: 'task-react-manufacturing'}
      ],
      uniforms: {
        variant: 'outlined',
      },
    }
  }
}

const schemaValidator = createValidator(schema)

export const bridge = new JSONSchemaBridge(schema, schemaValidator);