import { Button, Card, CircularProgress, Container } from '@material-ui/core';
import { AxiosError } from 'axios';
import React from 'react';
import { useAsyncFn } from 'react-use';
import { AutoForm } from 'uniforms-material';
import { giveRepositoryAccess as giveRepositoryAccessAPICall } from './api/giveRepositoryAccess';
import { bridge } from './form.schema';

function App() {
  const [model, setModel] = React.useState<{ email: string, repo: string }>({ email: localStorage.getItem('akademiasamouka-pl--form') || '', repo: '' })
  const [state, giveRepositoryAccess] = useAsyncFn(giveRepositoryAccessAPICall)

  return (
    <Container
      style={{
        padding: 16,
      }}
    >
      <Card
        style={{
          padding: 16,
        }}
      >
        <AutoForm
          model={model}
          schema={bridge}
          errorsField={() => null}
          showInlineError={true}
          onChangeModel={(newModel: {
            email: string;
            repo: string;
          }) => {
            localStorage.setItem('akademiasamouka-pl--form', newModel.email)
            setModel(newModel)
          }}
          submitField={
            () => (
              <Button
                style={{ marginTop: 16 }}
                type={'submit'}
                fullWidth={true}
                color={'primary'}
                variant={'contained'}
              >
                WYŚLIJ
              </Button>
            )
          }
          // @ts-ignore we are sure that all properties are present after validation
          onSubmit={giveRepositoryAccess}
        />
        <div>
          <p>
            Instrukcja:
          </p>
          <ol>
            <li>
              Wpisz adres e-mail, który podałeś jako „główny” w formularzu organizacyjnym na początku kursu.
            </li>
            <li>
              Wybierz repozytorium aktualnie przerabianego modułu.
            </li>
            <li>
              Informacje o tym, jak korzystać z repozytorium projektu, znajdziesz w module <a href={'https://app.akademiasamouka.pl/courses/take/js-tools/pdfs/26171540-javascript-narzedzia-141-stron'} target={'_blank'} rel={'noreferrer'}>s1e01 JavaScript: Narzędzia</a>.
            </li>
          </ol>
          <p>
            Jeśli chcesz wziąć udział w losowaniu projektu do code review, wykonaj pull request i zgłoś projekt przez formularz (link do niego znajdziesz w poniedziałkowym mailu).
          </p>
          <p>
            Termin końcowy zgłaszania projektu jest oznaczony w <a href={'https://calendar.google.com/calendar/u/0/embed?src=akademiasamouka@gmail.com&ctz=Europe/Warsaw'} target={'_blank'} rel={'noreferrer'}>harmonogramie</a> jako „CR – zgłoszenie projektu”.
          </p>
        </div>
        {
          state.loading ?
            <div
              style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', backgroundColor: 'rgba(255, 255, 255, 0.9)', zIndex: 9999 }}
            >
              <CircularProgress />
            </div>
            :
            null
        }
        {
          state.error ?
            <div
              style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', backgroundColor: 'rgba(255, 255, 255, 0.9)', zIndex: 9999 }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                  (state.error as AxiosError)?.response?.status === 404 ?
                    'Przesłane dane nie wyglądają na prawidłowe! Sprawdź e-mail!'
                    :
                    'Coś poszło nie tak!'
                }
                <br />
                <br />
                <Button
                  onClick={() => window.location.reload()}
                  color={'primary'}
                  variant={'contained'}
                >
                  SPRÓBUJ JESZCZE RAZ
                </Button>
              </div>
            </div>
            :
            null
        }
        {
          state.value !== undefined ?
            <div
              style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', backgroundColor: 'rgba(255, 255, 255, 0.9)', zIndex: 9999 }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                Masz dostęp!
                <br />
                <br />
                Wejdź na{' '}
                <a href={`https://github.com/devmentor-pl/${model.repo}`} target={'_blank'} rel={'noreferrer'}>https://github.com/devmentor-pl/{model.repo}</a>{' '}
                i potwierdź zaproszenie!
                <br />
                <br />
                WAŻNE! Zaproszenie wygaśnie po 7 dniach!
                <br />
                <br />
                <Button
                  onClick={() => window.location.reload()}
                  color={'primary'}
                  variant={'contained'}
                >
                  DODAJ KOLEJNY DOSTĘP
                </Button>
              </div>

            </div>
            :
            null
        }
      </Card>
    </Container>
  );
}

export default App;
